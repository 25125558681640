import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchProjects } from '../rtk/slices/projects-slice';
import { useTranslation } from 'react-i18next';

function Package () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { category } = useParams();
    const count = 0;
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projects);
    const { t, i18n } = useTranslation();

    const projects_en = projects.map(pro_en => {
        if (i18n.language == "en") {
            return ({
                "id": pro_en.id,
                "category": pro_en.category_en,
                "title": pro_en.title_en,
                "price": pro_en.price_en,
                "description": pro_en.description_en,
            })
        }
        return pro_en;
    })

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        // fetch projects
        dispatch(fetchProjects())
    }, [])

    return (
        <section className='projects package'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "أسعارنا" : "Pricing"}</span>
                    <p>{i18n.language == "ar" ? "أسعارنا" : "Pricing"}</p>
                </h2>

                <div className="cards">
                    {projects_en.map((project, counter) => {
                        return (
                            project.category == category &&
                            <div className="card" key={project.id}>
                                <h2>{project.title}</h2>
                                <h3>{project.price}</h3>
                                <p>
                                    {project.description.split(',').map((what) => {
                                        return (
                                            <>
                                                <span>{what}</span><br />
                                            </>
                                        )
                                    })}
                                </p>
                                <div style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><a target='_blank' href={`https://api.whatsapp.com/send?phone=201101191606&text=Rooteks Website: ${project.category} : ${project.title}`}>{i18n.language == "ar" ? "اطلب الأن" : "Order Now"}</a></span></button></div>

                            </div>
                        )
                    })}
                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>

            </div>
        </section>
    )
}

export default Package
